var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as THREE from "three";
import { Card } from "./_card";
function randomInt(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}
var CardTunnel = /** @class */ (function () {
    function CardTunnel(scene, canvas, camera, sizes, cameraPosition, gui) {
        this.scene = scene;
        this.canvas = canvas;
        this.camera = camera;
        this.sizes = sizes;
        this.cameraPosition = cameraPosition;
        this.gui = gui;
        this.objectCount = 400;
        this.radius = 5;
        this.splineControls = {
            rotationAngle: 0,
            depthStrength: 0.2,
            rotationStrength: 25,
            XYrandomness: 1,
            Zrandomness: 6.6,
        };
        this.imageUrls = [
            {
                img: "/img/22861773_10156071845420934_6833359284101290485_o.jpg",
                id: 1,
                title: "Testttt",
            },
            { img: "/img/KOVACS_2022_2.jpg", id: 1, title: "KOVACS" },
            { img: "/img/Koningsdag nieuwe markt.jpg", id: 1, title: "Koningsdag" },
            {
                img: "/img/Nielson 2022 - MAIN - credit EvaCaptures.jpg",
                id: 1,
                title: "Nielson",
            },
            { img: "/img/Perfsoto NSJO 2023.jpg", id: 1, title: "NSJO" },
            {
                img: "/img/Persfoto © 2022 Johan-Breijnl.jpeg",
                id: 1,
                title: "Johan-Breijnl",
            },
            {
                img: "/img/RUBEN_BLOCK_(c) Charlie De Keersmaecker_2.jpg",
                id: 1,
                title: "RUBEN_BLOCK_",
            },
            { img: "/img/Spinvis Kleur Liggend Titel  .jpg", id: 1, title: "Spinvis" },
            { img: "/img/lasergamen hedon.jpg", id: 1, title: "lasergamen" },
            {
                img: "/img/thedirtydaddies-sinnersarewinnerstour-zwolle-4x5.jpg",
                id: 1,
                title: "thedirtydaddies",
            },
            { img: "/img/thumbnail__97A5121.jpg", id: 1, title: "Titell" },
        ];
        this.objects = [];
        this.folder = gui.addFolder("CardTunnel");
        // Create an array of objects to place along the path
        for (var i = 0; i < this.objectCount; i++) {
            var card = new Card(this.scene, this.camera, this.cameraPosition);
            this.objects.push(card);
        }
        this.placeObjectsAlongPath();
        this.loadImagesAndApplyTextures();
        this.initGUI();
    }
    CardTunnel.prototype.placeObjectsAlongPath = function () {
        var angleStep = (Math.PI * 2) / this.splineControls.rotationStrength;
        var angle = this.splineControls.rotationAngle;
        for (var i = 0; i < this.objects.length; i++) {
            var card = this.objects[i];
            var object = card.object;
            var position = new THREE.Vector3();
            position.x =
                Math.cos(angle) * this.radius +
                    card.randomFactor * this.splineControls.XYrandomness;
            position.y =
                Math.sin(angle) * this.radius +
                    card.randomFactor * this.splineControls.XYrandomness;
            position.z =
                this.splineControls.depthStrength * i +
                    card.randomFactor * this.splineControls.Zrandomness;
            object.position.copy(position);
            // Calculate the rotation angle for this object
            var rotationAngle = angle + Math.PI / 2;
            // Set the rotation of the object around the Z axis
            object.rotation.z = rotationAngle;
            object.userData.originalSplinePosition = __assign({}, object.position);
            object.userData.originalSplineRotation = {
                x: 0,
                y: 0,
                z: object.rotation.z,
            };
            angle += angleStep;
            this.scene.add(object);
        }
    };
    CardTunnel.prototype.loadImagesAndApplyTextures = function () {
        var textureLoader = new THREE.TextureLoader();
        var that = this;
        for (var i = 0; i < this.objects.length; i++) {
            (function (index) {
                var event = that.imageUrls[index % that.imageUrls.length];
                textureLoader.load(event.img, function (texture) {
                    that.objects[index].applyTexture(texture);
                });
            })(i);
        }
    };
    CardTunnel.prototype.updateObjectPositionsAlongPath = function () {
        // TODO
        this.placeObjectsAlongPath();
    };
    CardTunnel.prototype.initGUI = function () {
        this.folder
            .add(this.splineControls, "rotationAngle", 0, 100, 0.05)
            .onChange(function () {
            this.updateObjectPositionsAlongPath();
        }.bind(this));
        this.folder
            .add(this.splineControls, "depthStrength", 0.1, 5, 0.01)
            .onChange(function () {
            this.updateObjectPositionsAlongPath();
        }.bind(this));
        this.folder
            .add(this.splineControls, "rotationStrength", 10, 50, 1)
            .onChange(function () {
            this.updateObjectPositionsAlongPath();
        }.bind(this));
        this.folder.add(this.splineControls, "XYrandomness", 1, 50, 0.1).onChange(function () {
            this.updateObjectPositionsAlongPath();
        }.bind(this));
        this.folder.add(this.splineControls, "Zrandomness", 1, 50, 0.1).onChange(function () {
            this.updateObjectPositionsAlongPath();
        }.bind(this));
    };
    CardTunnel.prototype.animateTunnel = function () {
        this.splineControls.rotationAngle += 0.0005;
        this.updateObjectPositionsAlongPath();
    };
    return CardTunnel;
}());
export { CardTunnel };
