import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
var Camera = /** @class */ (function () {
    function Camera(scene, canvas, sizes, pointer, gui) {
        this.scene = scene;
        this.canvas = canvas;
        this.sizes = sizes;
        this.pointer = pointer;
        this.gui = gui;
        this.cameraPosition = new THREE.Vector3(0, 0, 84.8);
        this.cameraSpeed = 0.05; //camera's movement speed
        this.colorMode = true;
        this.folder = gui.addFolder("Camera");
        this.setcamera();
        this.setControls();
        this.initGUI();
        this.addLights();
        this.raycaster = new THREE.Raycaster();
    }
    Camera.prototype.setcamera = function () {
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.camera.position.copy(this.cameraPosition);
        this.scene.add(this.camera);
    };
    Camera.prototype.setControls = function () {
        this.controls = new OrbitControls(this.camera, this.canvas);
        this.controls.enableDamping = true;
    };
    Camera.prototype.initGUI = function () {
        this.folder
            .add(this.cameraPosition, "z", 1, 100)
            .listen()
            .onChange(function (value) {
            this.camera.position.z = value;
            this.controls.update();
        }.bind(this));
    };
    Camera.prototype.resize = function (sizes) {
        this.camera.aspect =
            ((sizes === null || sizes === void 0 ? void 0 : sizes.width) || this.sizes.width) / ((sizes === null || sizes === void 0 ? void 0 : sizes.height) || this.sizes.height);
        this.camera.updateProjectionMatrix();
    };
    Camera.prototype.moveWithArrows = function (arrowUpPressed, arrowDownPressed) {
        // keyboard nav
        if (arrowUpPressed) {
            this.cameraPosition.z -= this.cameraSpeed;
        }
        if (arrowDownPressed) {
            this.cameraPosition.z += this.cameraSpeed;
        }
        // update only when need to
        if (arrowUpPressed || arrowDownPressed) {
            // Update the camera's position
            this.camera.position.copy(this.cameraPosition);
            this.controls.update();
        }
    };
    Camera.prototype.checkIntersected = function (disableEffects) {
        this.raycaster.setFromCamera(this.pointer, this.camera);
        var intersects = this.raycaster.intersectObjects(this.scene.children, false);
        if (intersects.length > 0) {
            if (this.intersected != intersects[0].object) {
                if (this.intersected) {
                    this.intersected.material.emissive.setHex(this.intersected.currentHex);
                }
                this.hovering = intersects[0].object;
                if (this.camera.position.distanceTo(this.hovering.position) < 30) {
                    this.intersected = intersects[0].object;
                    if (!disableEffects) {
                        this.intersected.currentHex =
                            this.intersected.material.emissive.getHex();
                        this.intersected.material.emissive.setHex(0xf9278a);
                    }
                }
            }
        }
        else {
            if (this.intersected) {
                this.intersected.material.emissive.setHex(this.intersected.currentHex);
            }
            this.intersected = null;
            this.hovering = null;
        }
    };
    Camera.prototype.addLights = function () {
        var light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(1, 1, 1).normalize();
        this.scene.add(light);
        var light2 = new THREE.AmbientLight(0x404040); // soft white light
        light2.intensity = 1.5;
        this.scene.add(light2);
    };
    return Camera;
}());
export { Camera };
