import * as THREE from "three";
var Scene = /** @class */ (function () {
    function Scene(canvas, gui) {
        this.gui = gui;
        this.scene = new THREE.Scene();
        this.clock = new THREE.Clock();
        //this.folder = gui.addFolder("Scene");
    }
    Scene.prototype.init = function () {
        this.initScene();
    };
    Scene.prototype.initScene = function () {
        this.scene.fog = new THREE.Fog(0x000000, 10, 47);
    };
    return Scene;
}());
export { Scene };
