import "./style.css";
import * as THREE from "three";
import { Scene } from "./sceneBase/scene";
import { Camera } from "./sceneBase/camera";
import { Renderer } from "./sceneBase/renderer";
import { CardTunnel } from "./sceneSubjects/_cardTunnel";
import { Debug } from "./sceneBase/debug";
var canvas = document.querySelector("canvas.webgl"); // TODO replace ! with a check and error
var debug = new Debug();
debug.init();
var activeCard;
var sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
};
var pointer = new THREE.Vector2();
var arrowUpPressed = false;
var arrowDownPressed = false;
var sceneTransitioning = false;
var scene = new Scene(canvas, debug.gui);
scene.init();
var camera = new Camera(scene.scene, canvas, sizes, pointer, debug.gui);
var renderer = new Renderer(scene.scene, canvas, camera.camera, sizes);
var cards = new CardTunnel(scene.scene, canvas, camera.camera, sizes, camera.cameraPosition, debug.gui);
window.addEventListener("resize", function () {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;
    camera.resize(sizes);
    renderer.update();
});
document.addEventListener("mousemove", onPointerMove);
function onPointerMove(event) {
    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
}
document.addEventListener("keydown", function (event) {
    if (event.code === "KeyW") {
        arrowUpPressed = true;
    }
    if (event.code === "KeyS") {
        arrowDownPressed = true;
    }
});
document.addEventListener("keyup", function (event) {
    arrowUpPressed = false;
    arrowDownPressed = false;
});
function closeCard(card) {
    var _a;
    sceneTransitioning = true;
    card === null || card === void 0 ? void 0 : card.moveBackTooriginalSplinePosition();
    (_a = card === null || card === void 0 ? void 0 : card.tween) === null || _a === void 0 ? void 0 : _a.eventCallback("onComplete", function () {
        activeCard = null;
        sceneTransitioning = false;
    });
}
document.addEventListener("click", function () {
    if (activeCard) {
        closeCard(activeCard);
        return;
    }
    var targetCard = cards.objects.find(function (card) { return card.object.uuid === camera.intersected.uuid; });
    if (camera.intersected && !activeCard) {
        activeCard = targetCard;
        activeCard === null || activeCard === void 0 ? void 0 : activeCard.updatePositionForCamera();
    }
});
var animate = function () {
    var elapsedTime = scene.clock.getElapsedTime();
    // Update controls (needed for damping)
    camera.controls.update();
    camera.checkIntersected(sceneTransitioning || !!activeCard);
    if (activeCard && (arrowUpPressed || arrowDownPressed)) {
        closeCard(activeCard);
    }
    if (!sceneTransitioning) {
        camera.moveWithArrows(arrowUpPressed, arrowDownPressed);
    }
    // Render
    renderer.render();
    if (!activeCard) {
        cards.animateTunnel();
    }
    // Call tick again on the next frame
    window.requestAnimationFrame(animate);
};
animate();
