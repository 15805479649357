import * as THREE from "three";
var Renderer = /** @class */ (function () {
    function Renderer(scene, canvas, camera, sizes) {
        this.scene = scene;
        this.canvas = canvas;
        this.camera = camera;
        this.sizes = sizes;
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
        });
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }
    Renderer.prototype.render = function () {
        this.renderer.render(this.scene, this.camera);
    };
    Renderer.prototype.update = function (sizes) {
        this.renderer.setSize((sizes === null || sizes === void 0 ? void 0 : sizes.width) || this.sizes.width, (sizes === null || sizes === void 0 ? void 0 : sizes.height) || this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };
    return Renderer;
}());
export { Renderer };
