//import dat from "three/examples/jsm/libs/lil-gui.module.min.js";
import * as dat from "lil-gui";
import Stats from "three/examples/jsm/libs/stats.module.js";
var Debug = /** @class */ (function () {
    function Debug() {
        this.gui = new dat.GUI();
        this.gui = new dat.GUI();
        this.stats = new Stats();
    }
    Debug.prototype.init = function () {
        this.initStats();
    };
    Debug.prototype.initStats = function () {
        var container = document.createElement("div");
        container.appendChild(this.stats.dom);
    };
    return Debug;
}());
export { Debug };
