import * as THREE from "three";
import { gsap } from "gsap/all";
function randomInt(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}
var Card = /** @class */ (function () {
    function Card(scene, camera, cameraPosition) {
        this.scene = scene;
        this.camera = camera;
        this.cameraPosition = cameraPosition;
        this.randomFactor = Math.random() - 0.5;
        this.initCard();
    }
    Card.prototype.initCard = function () {
        this.object = new THREE.Mesh(new THREE.PlaneGeometry(randomInt(0.2, 2), randomInt(0.2, 2)), new THREE.MeshLambertMaterial({
            color: Math.random() * 0xffffff,
        }));
        //this.object = new THREE.Mesh(
        //  new THREE.PlaneGeometry(randomInt(0.2, 2), randomInt(0.2, 2)),
        //  new THREE.MeshLambertMaterial({
        //    color: Math.random() * 0xffffff,
        //  })
        //);
    };
    Card.prototype.applyTexture = function (texture) {
        var material = new THREE.MeshLambertMaterial({
            map: texture,
        });
        material.emissiveIntensity = 0.5;
        var planeRotationZ = this.object.rotation.z;
        material.map.center.set(0.5, 0.5);
        material.map.rotation = -planeRotationZ;
        material.map.needsUpdate = true;
        // set the texture filtering mode
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        // get the aspect ratio of the texture
        var textureAspect = texture.image.width / texture.image.height;
        // get the aspect ratio of the plane
        var planeAspect = this.object.geometry.parameters.width /
            this.object.geometry.parameters.height;
        // calculate the scaling factor for the texture
        var scaleFactor;
        if (Math.abs(planeRotationZ) < Math.PI / 2) {
            scaleFactor = planeAspect / textureAspect;
        }
        else {
            scaleFactor = textureAspect / planeAspect;
        }
        // calculate the horizontal and vertical offsets for the texture
        var offsetX = (1 - scaleFactor) / 2;
        var offsetY = (1 - scaleFactor) / 2;
        // scale the texture and set its offsets
        material.map.repeat.set(scaleFactor, scaleFactor);
        material.map.offset.set(-offsetX + (1 - scaleFactor) / 2, -offsetY + (1 - scaleFactor) / 2);
        this.object.material = material;
    };
    Card.prototype.updatePositionForCamera = function () {
        // fixed distance from camera to the object
        var dist = 100;
        var cwd = new THREE.Vector3();
        this.camera.getWorldDirection(cwd);
        cwd.multiplyScalar(dist);
        cwd.add(this.camera.position);
        //planeMesh.position.set(cwd.x, cwd.y, cwd.z);
        //planeMesh.setRotationFromQuaternion(camera.quaternion);
        this.tween = gsap.to(this.object.position, {
            x: cwd.x,
            y: cwd.y,
            z: this.cameraPosition.z - 3,
            duration: 1,
            ease: "power2.inOut", // Use quadratic easing for smooth animation
        });
        var currentRotation = this.object.rotation.clone(); // Get the current rotation of the object
        var rotationAmount = shortestDistance(currentRotation, new THREE.Vector3(0, 0, 0));
        // Animate the rotation of the rotation object using GSAP
        gsap.to(this.object.rotation, {
            z: currentRotation.z + rotationAmount,
            duration: 1,
            ease: "power2.out",
        });
    };
    Card.prototype.moveBackTooriginalSplinePosition = function () {
        this.tween = gsap.to(this.object.position, {
            x: this.object.userData.originalSplinePosition.x,
            y: this.object.userData.originalSplinePosition.y,
            z: this.object.userData.originalSplinePosition.z,
            duration: 0.5,
            ease: "power2.inOut", // Use quadratic easing for smooth animation
        });
        var currentRotation = this.object.rotation.clone(); // Get the current rotation of the object
        var rotationAmount = shortestDistance(currentRotation, this.object.userData.originalSplineRotation);
        // Animate the rotation of the rotation object using GSAP
        gsap.to(this.object.rotation, {
            z: currentRotation.z + rotationAmount,
            duration: 0.5,
            ease: "power2.out",
        });
    };
    return Card;
}());
export { Card };
function shortestDistance(currentRotation, targetRotation) {
    var rotationDiff = targetRotation.z - currentRotation.z; // Calculate the difference in rotation
    var rotationAmount = rotationDiff % (Math.PI * 2); // Normalize the rotation amount to be within 0 to 2*PI range
    if (rotationAmount < -Math.PI)
        rotationAmount += Math.PI * 2; // If the rotation amount is less than -PI, add 2*PI to it
    if (rotationAmount > Math.PI)
        rotationAmount -= Math.PI * 2; // If the rotation amount is greater than PI, subtract 2*PI from it
    return rotationAmount;
}
